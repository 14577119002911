import _helper from "../helper";
import _types from "../types";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.respond = exports.Action = void 0;
var helper_1 = _helper;
var types_1 = _types;
var Action;
(function (Action) {
  Action["REQUEST"] = "APP::AUTH_CODE::REQUEST";
  Action["RESPOND"] = "APP::AUTH_CODE::RESPOND";
})(Action = exports.Action || (exports.Action = {}));
function respond(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.AuthCode,
    type: Action.RESPOND
  });
}
exports.respond = respond;
export default exports;
export const __esModule = exports.__esModule;
const _respond = exports.respond,
  _Action = exports.Action;
export { _respond as respond, _Action as Action };